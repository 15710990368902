























































import Vue from 'vue';
import TicketItem from './components/TicketItem.vue';
import Repayment from './components/Repayment.vue';
import { ResponseCode } from '~/util/constant';

export default Vue.extend({
  components: {
    TicketItem,
    Repayment,
  },
  async asyncData(ctx) {
    const pageNum = 1;
    const pageSize = 10;
    let activeTab = ""
    if(ctx.route.query.tab){
       activeTab = ctx.route.query.tab+"";
    }
    const ticketRes: any = await ctx.$axios.post('/api/orderApi/list', {
      pageNum,
      pageSize,
      status:activeTab
    });
    let ticket = {};
    if (ticketRes.code === ResponseCode.SUCCESS) {
      ticket = ticketRes.data;
    }

    return {
      pageNum,
      pageSize,
      ticket,
    };
  },
  data(): any {
    return {
      pageNum: 1,
      pageSize: 10,
      ticket: {},
      activeType: '0',
      repaymentVisible: false,
      currentTicket: {},
      // tabs: ['全部', '待付款', '出票中', '已出票', '已退款'],
      ETabStatus: {
        0: undefined,
        1: 1,
        2: 3,
        3: 4,
        4: 9,
      },
    };
  },
  mounted() {
    this.$previewRefresh();
  },
  created (){
    if(this.$route.query.tab){
      this.activeType = this.$route.query.tab;
    }
  },
  methods: {
    // 获取门票列表
    async handleGetList() {
      const { pageNum, pageSize } = this;
      const ticketRes: any = await this.$axios.post('/api/orderApi/list', {
        pageNum,
        pageSize,
        status: this.ETabStatus[this.activeType as any],
      });

      if (ticketRes.code === ResponseCode.SUCCESS) {
        this.ticket = ticketRes.data;
        this.$previewRefresh();
      }
    },
    // 换页
    handleChangePage(page: number) {
      this.pageNum = page;
      this.handleGetList();
    },
    showTicket(item: any) {
      const { status, ticket } = item;
      let content;
      // 免费门票
      if (ticket.isFreeTicket === 1) {
        content = `您已成功订购${item.exhibitionName}门票，请携带观展人身份证原件刷卡入场，也可凭身份证至现场服务台换领纸质进馆证！`;
      }
      // 出票状态 0-未出票 1-新出票 2-已查看
      if (status === 0 && ticket.isFreeTicket !== 1) {
        content = `您购买的${item.exhibitionName}门票正在出票中，将在展前1-2周以短信及邮件形式发送至您的手机及邮箱，请注意查收！`;
      }

      this.$confirm(content, '提示', {
        confirmButtonText: '确定',
        showCancelButton: false,
      }).catch((e: any) => {
        console.log('关闭弹窗', e);
      });
    },
    handleTabClick(tab: any) {
      this.activeType = tab.name;
      this.pageNum = 1;
      this.handleGetList();
    },
    handleShowRepayment(record: any) {
      Object.assign(this.$data, {
        repaymentVisible: true,
        currentTicket: record,
      });
    },
    cancelOrder(e: any) {
      // return false;
      this.$axios
        .post('/api/orderApi/delete', { id: e.id })
        .then((res: any) => {
          if (res.code == 1) {
            this.handleGetList();
            this.$message.success('订单已取消');
          }
        });
    },
  },
  head() {
    return {
      title: '我的门票-聚展',
      meta: [
        {
          name: 'keywords',
          content: '',
        },
        {
          name: 'description',
          content: '',
        },
        {
          name: 'robots',
          content: 'noindex, nofollow',
        },
      ],
    };
  },
});
